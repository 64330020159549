.dragging::before {
  background-color: rgba(255, 255, 255, 0.6);
  border: 5px dashed var(--links);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  content: 'ここにドロップ';
  font-size: 3rem;
  color: var(--links);
  font-weight: 600;
}
