input[type=text] {
  width: calc(100% - 20px);
  margin-bottom: 0.75rem;
}

label:not([for="input"]) {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

section {
  background: var(--background-alt);
  border: none;
  border-radius: 6px;
  outline: none;
  padding: 1rem;
  margin: 1rem 0;
  min-height: 3rem;
  font-family: 'Courier New', Courier, monospace;
}

button:not([type="submit"]) {
  margin-bottom: 3rem;
}

footer {
  border-top: 0;
  margin-top: 1rem;
}

.copying {
  margin-left: 0.5rem;
}
